
import { computed, defineComponent, onMounted, reactive, ref } from "vue"
import Datepicker from 'vue3-datepicker'
import { formatMoney } from '@/utils/Helpers'
import { AxiosResponse } from "axios"
import ApiService from "@/services/api.service"
import { useStore } from "vuex"

export interface ReportReturnWarehouse {
  Ref: string
  statut: string
  client_name: string
  GrandTotal: number
  paid_amount: number
  due: number
  payment_status: string
}

export interface ResponseReportReturnWarehouse {
  totalRows: number
  returns_sale: ReportReturnWarehouse[]
}

export default defineComponent({
  name: 'Return',
  components: {
    Datepicker
  },
  setup() {
    const picked = ref(new Date())
    const store = useStore()
    const warehouseId = computed<string>({
      get() {
        return store.state.warehouseReport.warehouseId
      },
      set(payload) {
        store.commit('warehouseReport/setWarehouseId', payload)
      }
    })
    const filter = reactive({
      limit: 100,
      SortField: 'Ref',
      SortType: 'asc',
      search: '',
      warehouse_id: warehouseId
    })
    const rows = ref<ReportReturnWarehouse[]>([])

    const getReturnSaleReport = async () => {
      try {
        const { data }: AxiosResponse<ResponseReportReturnWarehouse> = await ApiService.get('/report/Returns_Sale_Warehouse', {
          params: filter
        })
        rows.value = data.returns_sale
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(() => {
      getReturnSaleReport()
    })

    return {
      picked,
      filter,
      formatMoney,
      getReturnSaleReport,
      rows
    }
  }
})
